<template>
  <div class="sckz-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
    </a-range-picker>
    <m-select-more
      class="search_item"
      v-model="searchquery.publisherIdList"
      :allData="supplierList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="供应商"
      @change="changeSupplierIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.dspPlaceIdList"
      :allData="dspList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="广告源"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleSearch"
    />

    <m-select-more
      class="search_item"
      v-model="searchquery.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.packageList"
      :allData="packageList"
      :searchById="true"
      :hasIcon="false"
      :showId="true"
      label="产品包名"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.productList"
      :allData="productList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="推广产品"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.osList"
      :allData="osList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="操作系统"
      :showSelectedPart="false"
      width="220px"
      :canSelectAll="false"
      @change="handleSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.typeList"
      :allData="typeList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="素材类型"
      :showSelectedPart="false"
      width="220px"
      :canSelectAll="true"
      @change="handleSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.trafficBundleList"
      :allData="trafficBundleList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      label="流量包名"
      :hasSearch="true"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleSearch"
    />
    <div class="search_item">
      <a-input type="text" placeholder="标题、描述、素材ID" v-model.trim="searchquery.keywords" @change="handleDurationChange"/>
    </div>
    <!-- <div class="search_item">
      <a-button class="btn" type="primary" @click="handleSearch">查询</a-button>
    </div> -->
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getDspList, getRtbPlatformList } from '@/apiForManage/common'
import mixGetList from '@/mixins/getListForManage'
import { mapState } from 'vuex'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      // 查询字段
      searchquery: {
        dateEnd: undefined,
        dateStart: undefined,
        publisherIdList: [],
        placeIdList: [],
        platIdList: [],
        dspPlaceIdList: [],
        packageList: [],
        productList: [],
        osList: [],
        typeList: [],
        trafficBundleList: []
      },
      dspList: [],
      timer: null
    }
  },
  created () {
    const { endDate, startDate, end, start } = this.initDate(0, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    // this.getAppList()
    // this.getAppListForManage({})
    this.getSupplierList()
    this.getPlaceList()
    this.getPlatListByuser()
    this.getDspList()
    this.getProdAndpackageList()
    this.getTrafficBundleList()
    this.handleSearch()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    })
  },
  methods: {
    handleDurationChange () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleSearch()
      }, 1000)
    },
    async getDspList (platIds = []) {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: platIds
      })
      data.forEach((item) => {
        item.searchId = item.platPlaceId
      })
      this.dspList = data
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(90, 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString, test) {
      const time1 = date[0].valueOf()
      const time2 = date[1].valueOf()
      if (time2 - time1 > 1000 * 60 * 60 * 24 * 30) {
        return this.$message.error('时间范围不能超过30天')
      }
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    // 应用改变查广告位
    async changeSupplierIds (ids) {
      await this.getPlaceList(ids)
      this.handleSearch()
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      this.handleSearch()
    },
    // 获取所有广告平台
    async getRtbPlatformList () {
      const { data = [] } = await getRtbPlatformList()
      data.forEach((item) => {
        item.id = '' + item.id
      })
      this.platList = data
    },
    handleSearch () {
      this.$emit('handleSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less" scoped>
.sckz-search-container {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(240px, 1fr));
  gap: @compMargin;
  // display: flex;
  // flex-wrap: wrap;
  margin-bottom: calc(@mediumMargin - 10px);
  .search_item {
    // width: 260px;
    // margin-bottom: @compMargin;
    // margin-right: @compMargin;
    .btn{
      width: 88px;
    }
  }
}
</style>
